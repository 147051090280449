@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Oswald', sans-serif;
}

.homePageContainer {
    background-color: #1E1E1E;
    height: 95vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logoAndNameOfWebsite {
    display: flex;
    align-items: center;
}

.titleOfWebsite {
    font-family: 'Oswald', sans-serif;
    color: #CFA832;
    font-size: 48px;
    margin-left: 25px;
}

.dividerTwo {
    min-height: 10vh;
    max-width: 100%;
    width: 0;
    height: 0;
    border-top: 20vh solid #1E1E1E; /* Adjust height based on viewport height */
    border-left: 100vw solid #CFA832; /* Width based on viewport width */
    border-bottom: 0;
    border-right: 0;
}

.dividerThree {
    min-height: 10vh;
    max-width: 100%;
    width: 0;
    height: 0;
    border-top: 20vh solid #CFA832; /* Adjust height based on viewport height */
    border-right: 100vw solid #1E1E1E; /* Width based on viewport width */
    border-bottom: 0;
}

.logoImage {
    max-width: 150px;
    height: auto;
    margin-left: 1rem;
}

.contactButtonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.contactButton {
    text-decoration: none;
    color: black;
    font-weight: bold;
    background-color: #CFA832;
    padding: 15px;
    border-radius: 40px;
    font-family: 'Oswald', sans-serif;
}

.contactButton:hover {
    background-color: #d5b967;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(46, 46, 46, 0.2), 0 6px 20px 0 rgba(115, 115, 115, 0.29);
}

.homePageContainerTwo {
    background-color: #CFA832;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.sloganOfWebsite {
    font-family: 'Oswald', sans-serif;
    color: #2B2B2B;
    font-size: 48px;
}

.federationContainer {
    background-color: #CFA832;
    min-height: 95vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.kalenderTitle {
    font-family: 'Oswald', sans-serif;
    color: #2B2B2B;
    font-size: 34px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.kalenderItem {
    background-color: #1E1E1E;
    margin-bottom: 30px;
    padding: 35px;
    border-radius: 30px;
    text-decoration: none;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.29);
}

.titleOfEvent {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 24px;
    margin-bottom: 5px;
    font-weight: bold;
} 

.dateOfEvent {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 20px;
    margin-bottom: 5px;
}

.placeOfEvent {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 18px;
    text-transform: uppercase;
}

.aboutCombatJuJutsu {
    background-color: #1E1E1E;
    height: auto; /* Allow height to adjust based on content */
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px; /* Add padding to ensure content doesn’t touch edges */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}

.titleAndDescriptionOfCombatJuJutsu {
    flex: 1; /* Allows this section to take up available space */
    margin-right: 24px; /* Space between text and image */
    margin-left: 24px;
}

.titleAboutCombatJuJutsu {
    color: #CFA832;
    margin-bottom: 20px; /* Space below the title */
    margin-left: 24px;
}

.descriptionAboutCombatJuJutsu p {
    color: #CFA832;
    margin-bottom: 15px; /* Space between paragraphs */
    margin-left: 24px;
}

.combatJuJutsuPageButton {
    margin-top: 30px;
    background-color: #CFA832;
    padding: 15px;
    border-radius: 40px;
    width: 200px; /* Adjust width to fit better */
    text-align: center; /* Center text in button */
    margin-left: 24px;
}

.combatJuJutsuButton {
    text-decoration: none;
    color: black;
    font-weight: bold;
    text-transform: uppercase;
}

.combatJuJutsuPageButton:hover {
    background-color: #d5b967;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(46, 46, 46, 0.2), 0 6px 20px 0 rgba(115, 115, 115, 0.29);
}

.imageOfCombatJuJutsu {
    flex: 1; /* Allows the image section to take up available space */
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.imageOfCombat {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

.sponsorsOfTheFederation {
    display: flex;
    justify-content: space-evenly; /* Center the image horizontally */
    flex-direction: column;
    align-items: center; /* Center the image vertically */
    background-color: #1E1E1E;
    min-height: 50vh;
}

.titleSponsorsOfFederation {
    flex-direction: column;
    align-items: center; /* Center the image vertically */
}

.titleSponsors {
    font-family: 'Oswald', sans-serif;
    color: #CFA832;
    font-size: 34px;
    margin-bottom: 30px;
    margin-top: 30px;
}

.sponsorsOfFederationSection {
    display: flex;
    justify-content: space-between; /* Center the image horizontally */
    flex-direction: row;
    align-items: center; /* Center the image vertically */
}

.logoSponsor {
    height: auto;
    width: 150px;
    margin-left: 25px;
    margin-right: 25px;
}

.dividerExample {
    border-top: 2px solid #cfa8324f;
    border-radius: 10px;
    width: 50%; 
    margin: 20px 20px; 
}

.headquartersOfTheFederation {
    display: flex;
    justify-content: space-evenly; /* Center the image horizontally */
    flex-direction: row;
    align-items: center; /* Center the image vertically */
    background-color: #1E1E1E;
    min-height: 50vh;
}

.googleMapsCjjbf {
    border-radius: 10px;
    border: 0; 
    box-shadow: rgba(0,0,0,0.1) 0 0 30px;
}

.titleAdres {
    margin-bottom: 10px !important;
}
.titleContact {
    margin-bottom: 10px;
    margin-top: 40px;
}

.logoHeadQuarter {
    height: 130px;
    width: 130px;
}

.adresOfTheHeadQuarters {
    color: #CFA832;
    font-size: 1.125rem;
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) { 
    .homePageContainer {
        flex-direction: column;
        height: auto; /* Allow the container height to adjust based on content */
        min-height: 95vh; /* Minimum height for tablets */
        padding: 15px; /* Adjust padding for tablets */
    }

    .aboutCombatJuJutsu {
        flex-direction: column-reverse; /* Stack elements vertically */
        align-items: center; /* Center items horizontally */
        height: auto;
        padding: 15px;
    }

    .titleAndDescriptionOfCombatJuJutsu {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 0px;
    }

    .imageOfCombatJuJutsu {
        margin-top: 20px; /* Space between text and image in column layout */
    }

    .combatJuJutsuPageButton {
        margin: 20px 0px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .descriptionAboutCombatJuJutsu p {
        margin-bottom: 20px; /* Space between paragraphs on tablets */
    }

    .logoAndNameOfWebsite {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
    }

    .titleOfWebsite {
        font-size: 36px; /* Adjusted for better readability on tablets */
        margin-left: 0px;
    }

    .logoImage {
        max-width: 120px;
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .contactButtonContainer {
        margin-bottom: 20px;
    }

    .contactButton {
        padding: 12px 20px;
        font-size: 16px; /* Adjusted font size for better readability on tablets */

    }

    .sloganOfWebsite {
        font-size: 36px; /* Adjusted for better readability on tablets */
    }

    .kalenderTitle {
        font-size: 28px; /* Adjusted for better readability on tablets */
    }

    .kalenderItem {
        padding: 25px;
    }

    .titleOfEvent {
        font-size: 20px; /* Adjusted for better readability on tablets */
    }

    .dateOfEvent {
        font-size: 18px; /* Adjusted for better readability on tablets */
    }

    .placeOfEvent {
        font-size: 16px; /* Adjusted for better readability on tablets */
    }

    .headquartersOfTheFederation {
        flex-direction: column;
        text-align: center;
    }

    .logoHeadQuarter {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .adresOfTheHeadQuarters {
        margin-bottom: 30px;
    }

    .googleMapsCjjbf {
        margin-bottom: 30px;
        width: 320px;
        height: 250px;
        margin-left: 24px;
        margin-right: 24px;
    }

    .titleSponsors {
        font-family: 'Oswald', sans-serif;
        color: #CFA832;
        font-size: 34px;
        margin-bottom: 40px;
        margin-top: 40px;
    }

    .sponsorsOfFederationSection {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
    }

    .logoSponsor {
        height: auto;
        width: 150px;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 35px;
    }

    .dividerExample {
        border-top: 1px solid #CFA832;
        border-radius: 10px;
        width: 50%;
        margin: 20px 20px;
        margin-bottom: 40px;
    }
}

@media (max-width: 480px) {

    .titleAndDescriptionOfCombatJuJutsu {
        margin-left: 0px;
    }
    
    .titleOfWebsite {
        font-size: 28px; /* Larger font size for mobile devices */
    }

    .logoImage {
        max-width: 100px;
    }

    .contactButton {
        padding: 10px 15px;
        font-size: 14px; /* Adjusted font size for better readability on mobile */
    }

    .sloganOfWebsite {
        font-size: 28px; /* Adjusted for better readability on mobile devices */
    }

    .kalenderTitle {
        font-size: 24px; /* Adjusted for better readability on mobile devices */
    }

    .kalenderItem {
        padding: 15px;
    }

    .titleOfEvent {
        font-size: 18px; /* Adjusted for better readability on mobile devices */
    }

    .dateOfEvent {
        font-size: 16px; /* Adjusted for better readability on mobile devices */
    }

    .placeOfEvent {
        font-size: 14px; /* Adjusted for better readability on mobile devices */
    }
}
