@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.bestuurPageContainer{
    background-color: #1E1E1E;
    min-height: 95vh; /* Allow height to adjust based on content */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px; /* Add padding to ensure content doesn’t touch edges */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}

.bestuurItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.descriptionOfBestuurItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.nameOfBestuur {
    color: #CFA832;
    margin-bottom: 5px;
}
.titleOfBestuur {
    color: #CFA832;
    margin-bottom: 5px;
}
.gradeOfBestuur {
    color: #CFA832;
    margin-bottom: 5px;
}
.styleOfBestuur {
    color: #CFA832;
}

.photoOfPresident {
    border-radius: 250px;
    height: 200px;
    width: 200px;
}

.dividerTwoBestuur {
    min-height: 10vh;
    max-width: 100%;
    width: 0;
    height: 0;
    border-top: 20vh solid #1E1E1E; /* Adjust height based on viewport height */
    border-left: 100vw solid #CFA832; /* Width based on viewport width */
    border-bottom: 0;
    border-right: 0;
}

.dividerThreeBestuur {
    min-height: 10vh;
    max-width: 100%;
    width: 0;
    height: 0;
    border-top: 20vh solid #CFA832; /* Adjust height based on viewport height */
    border-left: 100vw solid #1E1E1E; /* Width based on viewport width */
    border-bottom: 0;
    border-right: 0;
}

.BestuursItemTwo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px; /* Add padding to ensure content doesn’t touch edges */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}

.BestuursItemThree {
    background-color: #1E1E1E;
    min-height: 75vh; /* Allow height to adjust based on content */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px; /* Add padding to ensure content doesn’t touch edges */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}

.nameOfBestuurTwo {
    color: #2b2b2b;
    margin-bottom: 5px;
    text-align: center;
}
.titleOfBestuurTwo {
    color: #2b2b2b;
    margin-bottom: 5px;
    text-align: center;
}
.gradeOfBestuurTwo {
    color: #2b2b2b;
    margin-bottom: 5px;
    text-align: center;
}
.styleOfBestuurTwo {
    color: #2b2b2b;
    text-align: center;
}

.nameOfBestuurThree {
    color: #CFA832;
    margin-bottom: 5px;
    text-align: center;
}
.titleOfBestuurThree {
    color: #CFA832;
    margin-bottom: 5px;
    text-align: center;
}
.gradeOfBestuurThree {
    color: #CFA832;
    margin-bottom: 5px;
    text-align: center;
}
.styleOfBestuurThree {
    color: #CFA832;
    text-align: center;
}

.coachesSection {
    display: flex;
    flex-direction: 'row';
    align-items: 'center';
    justify-content: space-evenly;
    max-width: 100%;
    background-color: #CFA832;
    min-height: 75vh; /* Allow height to adjust based on content */
}

.coachesSectionTwo {
    display: flex;
    flex-direction: 'row';
    align-items: 'center';
    justify-content: space-evenly;
    max-width: 100%;
    background-color: #1E1E1E;
    min-height: 75vh; /* Allow height to adjust based on content */
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) { 
    .coachesSection {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }

    .coachesSectionTwo {
        display: flex;
        flex-direction: column !important;
        align-items: center !important;
    }
}