@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li, a, button {
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #CFA832;
    text-decoration: none;
}

.logo {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: auto;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}

.logo-img {
    height: 40px; /* Adjust the size of the logo */
    margin-right: 10px; /* Space between logo and text */
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 5%;
    background-color: #1E1E1E;
    position: fixed; /* Makes the navbar sticky */
    top: 0; /* Sticks it to the top */
    width: 100%; /* Ensures it stretches across the screen */
    z-index: 1000; /* Ensures the navbar stays on top of other content */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a slight shadow for better visibility */
    border-bottom: 0.4px solid rgba(128, 128, 128, 0.309);
}

.nav__links {
    list-style: none;
}

.nav__links li {
    display: inline-block;
    padding: 0px 20px;
}

.nav__links li a {
    transition: all 0.3s ease 0s;
}

.nav__links li a:hover {
    color: #cfa83282;
}

button {
    margin-left: 20px;
    padding: 9px 25px;
    background-color: #2b2b2b;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

button:hover {
    background-color: #2b2b2b9b;
}

/* Reset button styles */
.toggle-button {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
}

.bar {
    height: 3px;
    width: 100%;
    background-color: #CFA832;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.toggle-button:focus {
    outline: none; /* Remove focus outline for better UX */
}
  

/* Responsive styles */
@media (max-width: 768px) {
    .nav__links {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: #1E1E1E;
        position: absolute;
        top: 60px;
        left: 0;
        text-align: center;
        /* Set initial height and opacity for smooth transition */
        height: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a slight shadow for better visibility */
        border-bottom: 0.4px solid rgba(128, 128, 128, 0.309);
    }

    .nav__links li {
        padding: 15px 0;
        display: block;
    }

    .cta {
        display: none; /* Hide contact button on mobile */
    }

    /* Hamburger button visible on mobile */
    .toggle-button {
        display: flex;
    }

    /* Show navigation links when active */
    .nav__links.active {
        height: 230px; /* Adjust based on your content */
        opacity: 1;
        visibility: visible;
    }
}