@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.footerContainer {
    height: 10vh;
    background-color: #1E1E1E;
    max-width: 100%;
    display: flex; /* Enable flexbox for the container */
    justify-content: center; /* Center the contents horizontally */
    align-items: center; /* Center the contents vertically */
    text-align: center; /* Center text horizontally */
    border-top: 0.4px solid gray;
}

.footerName {
    color: #CFA832;
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
}