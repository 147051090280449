@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap');

.aboutCombatJuJutsu {
    background-color: #2B2B2B;
    min-height: 90vh; /* Allow height to adjust based on content */
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px; /* Add padding to ensure content doesn’t touch edges */
    box-sizing: border-box; /* Ensure padding is included in height calculations */
}

.titleAndDescriptionOfCombatJuJutsu {
    flex: 1; /* Allows this section to take up available space */
    margin-right: 24px; /* Space between text and image */
}

.titleAboutCombatJuJutsu {
    color: #CFA832;
    margin-bottom: 20px; /* Space below the title */
    margin-left: 24px;
}

.descriptionAboutCombatJuJutsu p {
    font-size: 1.125rem;
    color: #CFA832;
    margin-bottom: 15px; /* Space between paragraphs */
    margin-left: 24px;
}

.combatJuJutsuPageButtonOne {
    margin-top: 30px;
    background-color: #CFA832;
    padding: 15px;
    border-radius: 40px;
    width: 270px; /* Adjust width to fit better */
    text-align: center; /* Center text in button */
    margin-left: 24px;
}

.combatJuJutsuButton {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
}

.combatJuJutsuPageButtonOne:hover {
    background-color: #d5b967;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(46, 46, 46, 0.2), 0 6px 20px 0 rgba(115, 115, 115, 0.29);
}

.imageOfCombatJuJutsu {
    flex: 1; /* Allows the image section to take up available space */
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center; /* Center the image vertically */
}

.imageOfCombat {
    max-width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

.titleSportSections {
    margin-bottom: 30px;
    color: #2B2B2B;
}

.combatSportsSectionsContainer {
    min-height: 45vh;
    background-color: #CFA832;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.combatSportsSections {
    display: flex;
    flex-direction: row;
}

.combatSportSection {
    background-color: #1E1E1E;
    margin-left: 15px;
    padding: 40px;
    border-radius: 30px;
}

.combatSportSection p {
    font-weight: bold;
    color: #CFA832;
    text-transform: uppercase;
    font-size: 19px;
}

.competitionRulesContainer {
    min-height: 45vh;
    background-color: #1E1E1E;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.titleCompetitionRulesContainer {
    margin-bottom: 10px;
}

.descriptionTextDownloadButtonCompetitionRules {
    margin-bottom: 30px;
}

.titleCompetitionRules {
    color: #CFA832;
}

.descriptionTextDownloadButton {
    color: #CFA832;
}

.downloadButtons {
    display: flex;
    flex-direction: row;
}

.downloadButton {
    font-weight: bold;
    margin-left: 15px;
    background-color: #CFA832;
    padding: 30px;
    width: 200px;
    border-radius: 30px;
    color: #2B2B2B;
    text-transform: uppercase;
    font-size: 19px;
}

.downloadButton:hover {
    background-color: #d5b967;
    transition: 0.2s;
    box-shadow: 0 4px 8px 0 rgba(46, 46, 46, 0.2), 0 6px 20px 0 rgba(115, 115, 115, 0.29);
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) { 
    .aboutCombatJuJutsu {
        flex-direction: column-reverse; /* Stack elements vertically */
        align-items: center; /* Center items horizontally */
        height: auto;
        padding: 15px;
    }

    .titleAndDescriptionOfCombatJuJutsu {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .imageOfCombatJuJutsu {
        margin-top: 20px; /* Space between text and image in column layout */
    }

    .imageOfCombat {
        margin-top: 60px;
    }

    .combatJuJutsuPageButton {
        margin: 20px 0px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .descriptionAboutCombatJuJutsu p {
        margin-bottom: 20px; /* Space between paragraphs on tablets */
    }

    .titleSportSections {
        margin-top: 50px;
    }
    
    .combatSportsSections {
        flex-direction: column;
        margin-bottom: 50px;
    }

    .combatSportSection {
        background-color: #1E1E1E;
        margin-left: 0px !important;
        margin-bottom: 20px;
        padding: 40px;
        border-radius: 30px;
    }

    .titleCompetitionRules {
        margin-top: 50px;
    }

    .downloadButtons {
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
    }

    .downloadButton {
        margin-bottom: 20px;
        margin-left: 0px !important;
    }
}

@media (max-width: 480px) {
    
}